/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
body {
  color: #553484 !important;
}
.required:after {
  content: '*';
  color: red;
}

.style-fonts {
  color: #553484 !important;

  &-bold {
    font-weight: 700 !important;
  }
}
.bg-light-primary-gradient {
  background: linear-gradient(45deg, #68d2dd85, #4f386c6b);
}
.bg-style-primary {
  background: #99e7f5;
}
.bg-style-warning {
  background: #85c0d4;
}

.bg-style-error {
  background: #94b7d4;
}
.bg-style-success {
  background: #8f99ca;
}
.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
  --mdc-switch-selected-track-color: $primary !important ;
  --mdc-switch-unselected-track-color: #d7f0f8 !important;
  --mdc-switch-unselected-handle-color: #778d94 !important;
  --mdc-switch-selected-icon-color: #0a7ea4 !important;
}
